.description {
    text-align: center;
    color:rgb(25, 18, 43);
    width: 73%;
    margin: auto;
}

.clientImage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.clientImage span {
    margin-left: 10px;
    color:rgb(25, 18, 43);
    border-bottom: 1px solid white;
    font-weight: 800;
    font-size: 17px;
}

.clientImage img {
    width: 50px;
    height: 50px;
    border-radius: 20px;
    object-fit: cover;
}

.reviewSection {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.reviewItem {
    width: 30%;
    /* width: 300px; */
    padding: 10px;
    margin: 1rem;
    cursor: pointer;
    border-radius: 10px;
    background-color: #ffffff;
    /* border: 1px solid #10102a; */
    transition: all .2s linear;
    box-shadow: 0px 3px 12px rgba(59, 74, 116,15)
}
.reviewItem:hover {
    
    box-shadow: 0px 4px 2px rgba(59, 74, 116,15);
    margin-top: 17px;
}

/* .reviewItem:hover {
    border-color: aqua;
    transform: scale(1.01);
    background-color: #090921;
    box-shadow: 0 0px 5px 0px #cbc0c0;
} */

.top {
    margin-bottom: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.top ul {
    display: flex;
    list-style: none;
}

.top ul li {
    padding-left: 4px;
}

article p {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 1rem;
    font-family: system-ui;
    color:rgb(25, 18, 43);

}


@media screen and (max-width:700px) {
    .container {
        height: auto;
    }

    .description {
        width: 90%;
    }
}

@media screen and (max-width:575px) {
    .reviewSection {
        padding: 0;
    }

    .reviewItem {
        width: 100%;
    }

    .clientImage {
        margin-bottom: 0.6rem;
    }

    .top {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        flex-direction: row;
        justify-content: space-between;
    }
}

.review_para {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: 'Poppins';
    letter-spacing: 0;
    color: #000000;
}








.h11 {
    /* text-align: center; */
    margin-bottom: 0px;
    font-weight: 900;
    font-size: 36px;
    color: #333;
  }
  
  .executive-leadership-team {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
  }
  

  .executive-leadership-team > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .executive-leadership-team > div {
margin: 20px;
width: 230px;
height: 250px;
position: relative;
border-radius: 10px;
cursor: pointer;
/* overflow: hidden; */
background-size: cover; /* Add this line */
background-position: center; /* Add this line */
}
  .executive-leadership-team > div:hover {
    opacity: .3;
}


.text1 {
position: absolute;
bottom: 0%;
left: 50%;
transform: translate(-50%, 10%);
text-align: center;
color: #fff;
width: max-content;
}
.text1 h2 {
    margin: 0;
    color: #fff;
    font-weight: 700;
    border-bottom: 1px solid white;
  }
  
  .text1 p {
      font-size: 16px;
      font-weight: 500;
      margin: 12px 0px 15px 0px;
  }

  
.container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .name {
    font-size: 30px;
    font-weight: bold;
    width: 90%;
    color:#333 ;

  }
  
  .close {
    font-size: 14px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    width: 10%;
    align-items: center;

  }
  
  .title {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* width: 40%; */
    color: #333;
    font-size: 20px;
    font-weight: 500;
  }
  
  .titles {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #333;
    font-size: 20px;
    font-weight: 500;
  }
  
  .details {
    width: 60%;
    color: #000000;
  }
  
  .details p {
    margin-bottom: 10px;
  }




  .change-mobile-veiw{
    display: none ;
  }

  .change-view{
    display: none ;
  }

 






  .container2 {
    background-color: rgb(25, 18, 43);
    margin: 0px auto;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .hh1 {
    text-align: center;
    color: white;
    margin-bottom: 30px;
    font-weight: 600;
    color: #ffcc00;
  }
  
  .pp {
    text-align: center;
    color: white;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
.slider {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ddd;
  margin-bottom: 30px;
}

.slider-fill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #ffcc00;
  width: 50%;
  transition: width 0.3s ease;
}

.slider-value {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.slider-buttons {
  display: flex;
  justify-content: center;
}

.slider-button {
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  color :black
}

.slider-button:hover {
  background-color: #ddd;
}

.info-box {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.info-item {
  text-align: center;
}

.info-item h2 {
  font-size: 1.2em;
  color: white;
  margin-bottom: 10px;
}

.info-item p {
  color: white;
}

.footer {
  text-align: center;
  color: #ffffff;
  font-size: 0.8em;
  margin-top: 20px;
}






.header1 {
  background-color: #ffffff;
  color: #000000;
  padding: 0px 20px;
  text-align: center;
}

.main1 {
  max-width: 1460px;
  margin: 0px auto;
  padding: 20px;
  /* background-color: #fff; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}



.ppp {
  line-height: 1.6;
  color: black;
  font-weight: 600;
  font-size: 20px;
}

.ppp2 {
  line-height: 1.6;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 17px;
}
.ppp3 {
 
  font-size: 4.0625rem;
}

.ppp1 {
  line-height: 1.6;
  color: rgb(255, 255, 255);
  font-weight: 400;
  /*
  font-size: 20px; */
}

.uul {
  list-style: none;
  padding: 0;
}

.uul > li {
  padding: 10px 0;
  color :rgb(255, 255, 255)
}

.button7 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}







.header8 {
  background-color: #ffffff;
  color: #100a11;
  padding: 20px;
  text-align: center;
  /* border-bottom: 1px solid #444; */
}

.clients5 {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.clients5 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.clients5 li {
  margin: 20px;
}

.clients5 img {
  width: 150px;
  height: 150px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
}

.stats4 {
  max-width: 1200px;
  margin: 0px auto;
  padding: 20px;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
}

.stats4 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  }
@media (min-width: 280px) and (max-width: 720px) {
  .stats4 ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
.ppp3 {
  font-size: 2.1rem !important;
}
}

.stats4 li {
  margin: 20px;
}

.stats4 span {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}





.blog-posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.blog-post {
  background-color: #f7f7f7;
  padding: 1em;
  margin: 20px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-post h2 {
  margin-top: 0;
}

.blog-post button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.blog-post button:hover {
  background-color: #444;
}

.blog-post span {
  font-size: 14px;
  color: #666;
}
.blog-post img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
}