* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.container {
  max-width: 133rem !important;
  margin: 0 auto !important;
  padding: 0 2.5rem !important;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #96040e #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #96040e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #96040e;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #ff2525;
}

.navbar {
  max-width: 133rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.7rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 99999;
  margin: 0 auto;
}

.navbar__img {
  width: 14.5rem;
}

/* .navbar__img img {
  width: 100%;
  height: 100%;
} */

.navbar__links {
  display: flex;
  list-style: none;
  gap: 2.1rem;
}

.navbar__links a {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #010103;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
}

.navbar__links a:hover {
  color: #96040e;
}

@media (max-width: 1000px) {
  .navbar__links {
    display: none;
  }
}

.navbar__buttons {
  display: flex;
  gap: 2.5rem;
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  align-items: center;
}

@media (max-width: 1000px) {
  .navbar__buttons {
    display: none;
  }
}

.navbar__buttons__sign-in {
  color: #010103;
  cursor: pointer;
  transition: all 0.3s;
}

.navbar__buttons__sign-in:hover {
  color: #96040e;
}

.navbar__buttons__register {
  background-color: #96040e;
  color: white;
  padding: 1.5rem 3rem;
  border-radius: 3px;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.35);
  transition: all 0.3s;
}

.navbar__buttons__register:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.5);
  background-color: #fa4226;
}

.navbar__buttons a {
  text-decoration: none;
}

.mobile-hamb {
  font-size: 2.8rem;
  display: none;
  cursor: pointer;
  transition: all 0.3s;
}

.mobile-hamb:hover {
  color: #96040e;
}

@media (max-width: 1000px) {
  .mobile-hamb {
    display: flex;
  }
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: #ffffff;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.mobile-navbar__close {
  font-size: 3rem;
  position: absolute;
  top: 3.5rem;
  right: 3.5rem;
  cursor: pointer;
  transition: all 0.3s;
}

.mobile-navbar__close:hover {
  color: #96040e;
}

.mobile-navbar__links {
  display: flex;
  flex-direction: column;
  list-style: none;
  font-size: 2.3rem;
  gap: 3rem;
  text-align: center;
}

.mobile-navbar__links li a {
  text-decoration: none;
  color: #010103;
  font-weight: 500;
  transition: all 0.3s;
}

.mobile-navbar__links li a:hover {
  color: #96040e;
}

.open-nav {
  left: 0;
}

.hero-section {
  width: 100%;
  height: 90vh;
  background-color: #f8f8f8;
  position: relative;
}

.bg-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 800px) {
  .bg-shape {
    display: none;
  }
}

.hero-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 800px) {
  .hero-content {
    justify-content: center;
  }
}

.hero-content__text {
  display: flex;
  flex-direction: column;
  z-index: 3;
  max-width: 50rem;
  margin-top: 5rem;
}

@media (max-width: 800px) {
  .hero-content__text {
    text-align: center;
    align-items: center;
  }
}

.hero-content__text h4 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  color: #010103;
}

.hero-content__text h1 {
  font-size: 4.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #010103;
  line-height: 1.2;
  margin-top: 1rem;
  margin-bottom: 2.3rem;
}

.hero-content__text h1 span {
  color: #96040e;
}

.hero-content__text p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.6;
  color: #706f7b;
  margin-bottom: 4rem;
}

.hero-content__text__btns {
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 450px) {
  .hero-content__text__btns {
    flex-direction: column;
  }
}

.hero-content__text__btns a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.hero-content__text__btns__book-ride {
  background-color: #96040e;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.35);
  transition: all 0.3s;
  border: 2px solid #96040e;
}

@media (max-width: 450px) {
  .hero-content__text__btns__book-ride {
    padding: 1.8rem 8rem;
  }
}

.hero-content__text__btns__book-ride:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.6);
  background-color: #fa4226;
}

.hero-content__text__btns__learn-more {
  background-color: #010103;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  border: 2px solid #010103;
  transition: all 0.3s;
}

.hero-content__text__btns__learn-more:hover {
  background-color: transparent;
  color: #010103;
}

.hero-content__car-img {
  z-index: 2;
  position: absolute;
  right: 0;
  width: 65%;
  margin-top: 5rem;
}

@media (max-width: 800px) {
  .hero-content__car-img {
    display: none;
  }
}

.scroll-up {
  position: fixed;
  font-size: 2.5rem;
  color: white;
  background-color: #96040e;
  border: 3px solid white;
  width: 2rem;
  height: 2rem;
  bottom: 5rem;
  right: 2rem;
  z-index: 20;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
}

.show-scroll {
  display: flex;
}

.book-section {
  position: relative;
  background: linear-gradient(to bottom, #f8f8f8 20%, #ffffff 80%);
}

.book-content {
  margin: 0 auto;
  margin-bottom: 10rem;
}

.book-content__box {
  /* background-color: #ffffff; */
  width: 100%;
  padding: 4rem 4.5rem 5rem 2.5rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  position: relative;
  z-index: 4;
  color: white;
  border-radius: 5px;
  background-image: url("/src/images/book-car/book-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: #010103;
}

@media (max-width: 400px) {
  .book-content__box {
    padding: 4rem 2.5rem 5rem 2.5rem;
  }
}

.book-content__box h2 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 2.7rem;
}

.book-content__box .box-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 2rem;
}

.book-content__box .box-form b {
  color: #96040e;
}

@media (max-width: 1000px) {
  .book-content__box .box-form {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 630px) {
  .book-content__box .box-form {
    grid-template-columns: 1fr;
  }
}

.book-content__box .box-form__car-type {
  display: flex;
  flex-direction: column;
}

.book-content__box .box-form__car-type label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
}

.book-content__box .box-form__car-type label i {
  color: #96040e;
}

.book-content__box .box-form__car-type select {
  font-size: 1.5rem;
  color: #ababab;
  font-family: "Rubik", sans-serif;
  border: 1px solid #ccd7e6;
  border-radius: 3px;
  font-weight: 400;
  padding: .9rem 1.3rem;
  outline: none;
}

.book-content__box .box-form__car-time {
  display: flex;
  flex-direction: column;
  position: relative;
}

.book-content__box .box-form__car-time input {
  outline: none;
  color: #878585;
  padding-right: 3rem;
  border: 1px solid #ccd7e6;
}

.book-content__box .box-form__car-time label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.book-content__box .box-form__car-time label i {
  color: #96040e;
}

.book-content__box .box-form__car-time ::-webkit-datetime-edit {
  padding: 1.3rem 1.3rem;
}

.book-content__box .box-form__car-time ::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 1.6rem;
}

.book-content__box button {
  padding: 1.3rem 1.3rem;
  height: -moz-fit-content;
  height: fit-content;
  align-self: self-end;
  border: none;
  font-family: "Rubik", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
  background-color: #96040e;
  box-shadow: 0 10px 15px rgba(255, 83, 48, 0.35);
  cursor: pointer;
  transition: all 0.3s;
}

.book-content__box button:hover {
  box-shadow: 0 10px 15px rgba(255, 83, 48, 0.55);
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 1rem 1.4rem;
  margin-bottom: 1.8rem;
  margin-top: -1rem;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.error-message i {
  cursor: pointer;
}

.booking-done {
  color: #2a6817;
  background-color: #c3fabe;
  border-color: #f5c6cb;
  padding: 1rem 1.4rem;
  margin-bottom: 1.8rem;
  margin-top: -1rem;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.booking-done i {
  cursor: pointer;
}

.active-modal {
  opacity: 1 !important;
  display: flex !important;
}

.modal-overlay {
  opacity: 0;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999999;
  top: 0;
  right: 0;
}

.booking-modal {
  opacity: 0;
  display: none;
  flex-direction: column;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999999999999;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 83rem;
  height: 100vh;
  border: 2px solid white;
  background-color: rgb(255, 255, 255);
  padding-right: 2px;
  color: #010103;
}

@media (max-width: 800px) {
  .booking-modal {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .booking-modal {
    top: 50%;
  }
}

.booking-modal__title {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: #96040e;
  color: white;
  align-items: center;
}

.booking-modal__title h2 {
  font-size: 2.4rem;
  text-transform: uppercase;
}

.booking-modal__title i {
  font-size: 2.5rem;
  color: rgba(255, 255, 255, 0.919);
  cursor: pointer;
  transition: all 0.2;
}

.booking-modal__title i:hover {
  color: white;
}

.booking-modal__message {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 3rem;
  background-color: #ffeae6;
}

.booking-modal__message h4 {
  font-size: 1.9rem;
  font-weight: 700;
  color: #96040e;
}

.booking-modal__message h4 i {
  font-size: 2.5rem;
}

.booking-modal__message p {
  font-size: 1.7rem;
  color: #777777;
  font-weight: 500;
  line-height: 1.6;
}

.booking-modal__car-info {
  background-color: white;
  padding: 3rem 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(119, 119, 119, 0.6235294118);
}

@media (max-width: 650px) {
  .booking-modal__car-info {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.booking-modal__car-info__dates {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.booking-modal__car-info__dates h5 {
  font-size: 1.8rem;
  color: #96040e;
}

.booking-modal__car-info__dates span {
  display: flex;
  gap: 1rem;
}

@media (max-width: 650px) {
  .booking-modal__car-info__dates span {
    grid-template-columns: 1fr;
    text-align: center;
    justify-content: center;
  }
}

.booking-modal__car-info__dates span i {
  font-size: 1.8rem;
  padding-top: 0.2rem;
  color: #777777;
}

@media (max-width: 650px) {
  .booking-modal__car-info__dates span i {
    display: none;
  }
}

.booking-modal__car-info__dates span h6 {
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
}

.booking-modal__car-info__dates span p {
  font-size: 1.6rem;
  color: #777777;
}

.booking-modal__car-info__model {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

@media (max-width: 650px) {
  .booking-modal__car-info__model {
    margin-top: 3.5rem;
  }
}

.booking-modal__car-info__model h5 {
  font-size: 1.8rem;
  color: #96040e;
}

.booking-modal__car-info__model h5 span {
  color: #010103;
}

.booking-modal__car-info__model img {
  width: 100%;
  height: auto;
}

.booking-modal__person-info {
  padding: 3rem 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.booking-modal__person-info h4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #96040e;
  margin-bottom: 2rem;
}

.input-time {
  text-align: center;
  width: 8rem;
  font-size: 14px;
  cursor: pointer;
}

.info-form {
  display: flex;
  flex-direction: column;
}

.info-form__checkbox {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.6rem;
  color: #565454;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.info-form__checkbox input {
  width: 1.6rem;
  height: 1.6rem;
}

.info-form__1col {
  grid-template-columns: 1fr !important;
}

.info-form__2col,
.info-form__1col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 1rem 0;
}

@media (max-width: 650px) {

  .info-form__2col,
  .info-form__1col {
    grid-template-columns: 1fr;
  }
}

.info-form__2col span,
.info-form__1col span {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.info-form__2col span label,
.info-form__1col span label {
  font-size: 1.6rem;
  font-weight: 500;
  color: #777777;
}

.info-form__2col span label b,
.info-form__1col span label b {
  color: #96040e;
}

.info-form__2col span input,
.info-form__1col span input {
  padding: 14px 15px;
  background-color: #dbdbdb;
  color: #555;
  font-size: 1.5rem;
  font-weight: 500;
  outline: none;
  border: none;
}

.reserve-button {
  background-color: #dbdbdb;
  margin: 0 -3rem;
  padding: 3rem;
  text-align: right;
}

@media (max-width: 650px) {
  .reserve-button {
    text-align: center;
  }
}

.reserve-button button {
  font-size: 2.4rem;
  color: white;
  font-weight: 700;
  background-color: #96040e;
  border: 1px solid yellow;
  padding: 1.2rem 2rem;
  cursor: pointer;
  transition: all 0.2s;
}

.reserve-button button:hover {
  background-color: #fe3516;
}

.dates-div {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}

.plan-section {
  background-color: #ffffff;
  padding: 5.3rem 0  2.3rem 0;
}

.plan-container {
  display: flex;
  flex-direction: column;
}

.plan-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
}

.plan-container__title h3 {
  font-size: 2.4rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.plan-container__title h2 {
  font-size: 4.2rem;
  font-family: "Poppins", sans-serif;
  margin: 1.3rem 0 3rem 0;
}

.plan-container__boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  /* margin-top: 3.7rem; */
  padding: 0 3rem;
}

@media (max-width: 1021px) {
  .plan-container__boxes {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}

@media (max-width: 800px) {
  .plan-container__boxes {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}

.plan-container__boxes__box {
  text-align: center;
  padding: 1rem 6rem;
}

@media (max-width: 500px) {
  .plan-container__boxes__box {
    padding: 1rem 1rem;
  }
}

.plan-container__boxes__box img {
  width: 17rem;
  height: auto;
}

.plan-container__boxes__box h3 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}

.plan-container__boxes__box p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.43;
}
.fgfgfg{
  font-weight: bold !important;
}
.navbar{
  padding: 0.7rem 2rem !important;
  position: relative !important;

}
.pick-section {
  padding: 2rem 0;
}

.pick-container {
  display: flex;
  flex-direction: column;
}

.pick-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
  max-width: 50rem;
  margin-bottom: 5rem;
}

.pick-container__title p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.5;
}

.pick-container__title h3 {
  font-size: 2.4rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.pick-container__title h2 {
  font-size: 4.2rem;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem 0;
}

.pick-container__car-content {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

@media (max-width: 1050px) {
  .pick-container__car-content {
    flex-direction: column;
    gap: 5rem;
  }
}

.pick-box {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.pick-box button {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 1.5rem 2.5rem;
  background-color: #e9e9e9;
  transition: all 0.2s;
  text-align: left;
}

.pick-box button:hover {
  background-color: #96040e;
  color: white;
}

.pick-car {
  width: 50rem;
  position: relative;
}

@media (max-width: 700px) {
  .pick-car {
    width: 100%;
  }
}

.pick-car img {
  width: 100%;
  margin-top: 6rem;
}

.pick-description {
  width: 25rem;
}

.pick-description__price {
  width: 100%;
  background-color: #96040e;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: white;
  padding: 0.3rem 1.9rem;
  white-space: nowrap;
}

.pick-description__price span {
  font-size: 2.8rem;
  font-weight: 700;
}

.pick-description__table {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  font-size: 1.4rem;
}

.pick-description__table__col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  grid-template-rows: auto;
  padding: 0.9rem 0.5rem;
  border-bottom: 2px solid #706f7b;
  border-right: 2px solid #706f7b;
  border-left: 2px solid #706f7b;
}

.pick-description__table__col span:nth-child(1) {
  border-right: 2px solid #706f7b;
}

.cta-btn {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: white;
  font-family: "Poppins", sans-serif;
  background-color: #96040e;
  padding: 1rem 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.4rem;
  transition: all 0.3s;
  box-shadow: 6px 6px 0 #efe9e9;
}

.cta-btn:hover {
  background-color: #e9381d;
}

.box-cars {
  gap: 11rem;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .box-cars {
    gap: 2rem;
  }
}

@media (max-width: 700px) {
  .box-cars {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
}

.colored-button {
  background-color: #96040e !important;
  color: white;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(0, 0, 0, 0.2588235294);
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  right: 43%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.banner-section {
  width: 100%;
  height: auto;
  display: flex;
  background-color: #2d2d2d;
  margin: 8rem 0;
  padding: 6rem 0;
  text-align: center;
}

.banner-content {
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
}

.banner-content__text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.banner-content__text h2 {
  font-size: 5.2rem;
  line-height: 1.3;
}

@media (max-width: 550px) {
  .banner-content__text h2 {
    font-size: 4.2rem;
  }
}

.banner-content__text p {
  font-size: 2.4rem;
}

@media (max-width: 550px) {
  .banner-content__text p {
    font-size: 2rem;
  }
}

.banner-content__text span {
  color: #96040e;
  font-weight: 500;
}

.choose-section {
  background-color: white;
  padding: 2rem 0 10rem 0;
  background-image: url("/src/images/chooseUs/bg.png");
  background-position: -225px 255px;
  background-size: cover;
  background-repeat: no-repeat;
}

.choose-container {
  display: flex;
  flex-direction: column;
}

.choose-container__img {
  width: 90%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 550px) {
  .choose-container__img {
    width: 100%;
  }
}

.text-container {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
}

@media (max-width: 1000px) {
  .text-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
  }
}

.text-container__left {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
}

@media (max-width: 1000px) {
  .text-container__left {
    align-items: center;
    text-align: center;
  }
}

.text-container__left h4 {
  font-size: 2.2rem;
  margin-bottom: 0.7rem;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.text-container__left h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.text-container__left p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #706f7b;
  margin-bottom: 3.3rem;
}

.text-container__left a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #96040e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.35);
  transition: all 0.3s;
  border: 2px solid #96040e;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.text-container__left a:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.6);
  background-color: #fa4226;
}

.text-container__right {
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  max-width: 44rem;
}

.text-container__right__box {
  display: flex;
}

@media (max-width: 550px) {
  .text-container__right__box {
    flex-direction: column;
    align-items: center;
  }
}

.text-container__right__box img {
  width: 11rem;
  height: 11rem;
  margin-right: 1.1rem;
}

.text-container__right__box__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.text-container__right__box__text h4 {
  font-size: 2.4rem;
}

.text-container__right__box__text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Rubik", sans-serif;
  line-height: 1.3;
}

.testimonials-section {
  background-color: #f8f8f8;
  padding: 2rem 0;
  color: #010103;
}

.testimonials-content {
  display: flex;
  flex-direction: column;
}

.testimonials-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 70rem;
  margin-bottom: 5rem;
}

.testimonials-content__title h4 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.testimonials-content__title h2 {
  font-size: 4.2rem;
  margin-bottom: 1.4rem;
}

.testimonials-content__title p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.4;
}

.all-testimonials {
  display: flex;
  gap: 3rem;
  width: 100%;
  justify-content: center;
  padding: 3rem;
}

@media (max-width: 1000px) {
  .all-testimonials {
    padding: 0;
  }
}

.all-testimonials__box {
  background-color: white;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.08);
  width: 54rem;
  padding: 2.5rem;
  position: relative;
}

@media (max-width: 1000px) {
  .all-testimonials__box {
    padding: 2rem 1rem;
  }
}

.all-testimonials__box p {
  font-size: 1.6rem;
  font-weight: 500;
}

.all-testimonials__box__name {
  display: flex;
}

.all-testimonials__box__name__profile {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 3rem;
}

.all-testimonials__box__name__profile img {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
}

.all-testimonials__box__name__profile h4 {
  font-size: 1.8rem;
}

.all-testimonials__box__name__profile p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.quotes-icon {
  font-size: 6.2rem;
  color: #96040e;
  position: absolute;
  bottom: 33px;
  right: 60px;
}

@media (max-width: 470px) {
  .quotes-icon {
    display: none;
  }
}

@media (max-width: 900px) {
  .box-2 {
    display: none;
  }
}

.faq-section {
  background-image: url("/src/images/faq/car.png");
  padding: 2rem 0;
  /* padding: 10rem 0; */
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 70%;
}

.faq-content {
  display: flex;
  flex-direction: column;
  color: #010103;
}

.faq-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 80rem;
  line-height: 1.5;
}

.faq-content__title h5 {
  font-size: 2.2rem;
}

.faq-content__title h2 {
  font-size: 4.2rem;
  margin-bottom: 1.7rem;
}

.faq-content__title p {
  font-size: 1.6rem;
  color: #706f7b;
}

.all-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 7rem 2rem 0rem 2rem;
  /* margin-top: 7rem; */
}

.faq-box {
  display: flex;
  flex-direction: column;
  color: #010103;
  background-color: white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  width: 80rem;
  cursor: pointer;
}

@media (max-width: 850px) {
  .faq-box {
    width: 100%;
  }
}

.faq-box__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1.8rem 4.5rem;
  transition: 0.15s ease;
}

.faq-box__question p {
  font-size: 1.8rem;
  font-weight: 500;
}

.faq-box__question i {
  font-size: 2rem;
}

.faq-box__answer {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.7;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease;
  padding: 0 4.5rem;
}

.active-answer {
  max-height: 20rem;
  padding: 2.8rem 4.5rem;
  transition: 0.4s ease;
}

@media (max-width: 550px) {
  .active-answer {
    max-height: 30rem;
  }
}

@media (max-width: 420px) {
  .active-answer {
    max-height: 55rem;
  }
}

.active-question {
  background-color: #96040e;
  color: white;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.35);
}

.download-section {
  background-image: url("/src/images/banners/bg02.png");
  background-color: #f8f8f8;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 10rem 0;
}

@media (max-width: 700px) {
  .download-section {
    background-image: none;
  }
}

.download-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 55rem;
  text-align: left;
}

@media (max-width: 700px) {
  .download-text {
    text-align: center;
    margin: 0 auto;
  }
}

.download-text h2 {
  font-size: 4.2rem;
  color: #010103;
}

.download-text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
}

.download-text__btns {
  display: flex;
  gap: 3rem;
  margin-top: 2rem;
}

@media (max-width: 700px) {
  .download-text__btns {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  .download-text__btns {
    flex-direction: column;
    align-items: center;
  }
}

.download-text__btns img {
  width: 40%;
  cursor: pointer;
}

@media (max-width: 550px) {
  .download-text__btns img {
    width: 22rem;
  }
}

footer {
  background-color: #ffffff;
  padding: 10rem 0;
}

.footer-content {
  display: grid;
  color: #010103;
  grid-template-columns: 27fr 21fr 21fr 21fr;
  gap: 8rem;
  justify-content: center;
  text-align: left;
  color: #010103;
}

@media (max-width: 1100px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 650px) {
  .footer-content {
    grid-template-columns: 1fr;
  }
}

.footer-content__1 {
  list-style: none;
}

@media (max-width: 650px) {
  .footer-content__1 {
    text-align: center;
  }
}

.footer-content__1 li a {
  text-decoration: none;
  color: #010103;
  transition: all 0.2s;
}

.footer-content__1 li a:hover {
  color: #96040e;
}

.footer-content__1 li:nth-child(1) {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
}

.footer-content__1 li:nth-child(1) span {
  font-weight: 700;
}

.footer-content__1 li:nth-child(2) {
  font-size: 1.6rem;
  color: #706f7b;
  line-height: 1.7;
  margin-bottom: 3rem;
}

.footer-content__1 li:nth-child(3),
.footer-content__1 li:nth-child(4) {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

.footer-content__2 {
  list-style: none;
}

@media (max-width: 650px) {
  .footer-content__2 {
    text-align: center;
  }
}

.footer-content__2 a {
  text-decoration: none;
  color: #010103;
  transition: all 0.2s;
}

.footer-content__2 a:hover {
  color: #96040e;
}

.footer-content__2 li {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.footer-content__2 li:nth-child(1) {
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  cursor: auto;
}

.footer-content__2 input {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  background-color: #ececec;
  border-width: 1px;
  border-color: transparent;
  padding: 10px 60px;
  outline: none;
  margin-top: 1rem;
}

@media (max-width: 1100px) {
  .footer-content__2 input {
    width: 100%;
  }
}

.submit-email {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #96040e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.25);
  transition: all 0.3s;
  border: 2px solid #96040e;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  width: 100%;
}

.submit-email:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.4);
  background-color: #fa4226;
}

.hero-pages {
  width: 100%;
  height: 41rem;
  background-image: url("/src/images/hero/heroes-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-pages__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.92);
}

.hero-pages__text {
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
  width: 100%;
  height: 41rem;
  justify-content: center;
  color: #010103;
}

.hero-pages__text h3 {
  font-size: 3.6rem;
  margin-bottom: 0.5rem;
}

.hero-pages__text p {
  font-size: 1.6rem;
  font-weight: 600;
}

.hero-pages__text p a {
  color: #010103;
  text-decoration: none;
  transition: all 0.2s;
}

.hero-pages__text p a:hover {
  color: #96040e;
}

.about-main {
  margin: 0rem auto;
  display: flex;
  gap: 5rem;
  max-width: 100rem;
  align-items: center;
}

@media (max-width: 960px) {
  .about-main {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    max-width: 49rem;
  }
}

@media (max-width: 520px) {
  .about-main {
    max-width: 100%;
  }
}

.about-main__img {
  width: 43rem;
  height: 43rem;
}

@media (max-width: 960px) {
  .about-main__img {
    margin: 0 auto;
  }
}

@media (max-width: 520px) {
  .about-main__img {
    width: 100%;
    height: auto;
  }
}

.about-main__text {
  color: #010103;
  display: flex;
  flex-direction: column;
}

.about-main__text h3 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

.about-main__text h2 {
  font-size: 4.2rem;
  line-height: 1.2;
}

.about-main__text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  margin-top: .5rem;
  margin-bottom: 2rem;
}

.about-main__text__icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
}

@media (max-width: 520px) {
  .about-main__text__icons {
    grid-template-columns: 1fr;
    margin: 0 auto;
    gap: 0;
  }

  .about-main__text__icons p {
    margin-top: 0;
  }
}

.about-main__text__icons__box {
  display: flex;
  flex-direction: column;
}

@media (max-width: 520px) {
  .about-main__text__icons__box {
    align-items: center;
  }
}

.about-main__text__icons__box .last-fk {
  width: 5rem;
}

.about-main__text__icons__box img {
  width: 7rem;
}

.about-main__text__icons__box span {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 520px) {
  .about-main__text__icons__box span {
    text-align: center;
    flex-direction: column;
  }
}

.about-main__text__icons__box span h4 {
  font-size: 4.6rem;
}

.book-banner {
  display: flex;
  width: 100%;
  height: 20rem;
  background-image: url("/src/images/banners/book-banner.png");
  position: relative;
  margin-top: 7rem;
}

@media (max-width: 750px) {
  .book-banner {
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem 0;
  }
}

.book-banner__overlay {
  background-color: #2d2d2d;
  opacity: 0.89;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.text-content {
  color: white;
  z-index: 5;
  position: relative;
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  gap: 5rem;
}

@media (max-width: 750px) {
  .text-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }
}

.text-content h2 {
  font-size: 3.2rem;
}

.text-content span {
  display: flex;
  font-size: 2.7rem;
  gap: 1rem;
  align-items: center;
  color: #96040e;
  white-space: nowrap;
}

.models-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 3rem;
  align-items: center;
  text-align: center;
  padding: 10rem 0;
  width: 110rem;
  margin: 0 auto;
}

@media (max-width: 1150px) {
  .models-div {
    grid-template-columns: 1fr 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (max-width: 800px) {
  .models-div {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.models-div__box {
  border: 1px solid #d5d5d5;
  border-radius: 0.3rem;
  display: flex;
  width: 35rem;
  flex-direction: column;
}

@media (max-width: 400px) {
  .models-div__box {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.models-div__box__img {
  width: 100%;
  height: auto;
  border-radius: 0.3rem;
}

.models-div__box__img img {
  width: 100%;
  height: 27rem;
}

.models-div__box__descr {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  color: #010103;
}

.models-div__box__descr__name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.models-div__box__descr__name-price__name {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;
}

.models-div__box__descr__name-price__name p {
  font-size: 2.4rem;
  font-weight: 700;
}

.models-div__box__descr__name-price__name span {
  display: flex;
  gap: 0.4rem;
}

.models-div__box__descr__name-price__name span i {
  font-size: 1.4rem;
  color: #ffc933;
}

.models-div__box__descr__name-price__price {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.models-div__box__descr__name-price__price h4 {
  font-size: 2.8rem;
}

.models-div__box__descr__name-price__price p {
  font-size: 1.6rem;
}

.models-div__box__descr__name-price__details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  -moz-column-gap: 7rem;
  column-gap: 7rem;
  margin-top: 2.5rem;
  margin: 2rem auto;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #c6c6c6;
}

.models-div__box__descr__name-price__details span {
  font-size: 1.8rem;
  font-weight: 500;
  color: #777777;
  text-align: left;
}

.models-div__box__descr__name-price__details span i {
  color: #010103;
}

.models-div__box__descr__name-price__btn {
  background-color: #96040e;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.35);
  transition: all 0.3s;
  border: 2px solid #96040e;
  font-size: 1.8rem;
  cursor: pointer;
}

.models-div__box__descr__name-price__btn:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.6);
  background-color: #fa4226;
}

.models-div__box__descr__name-price__btn a {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.team-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 4rem;
  align-items: center;
  text-align: center;
  padding: 10rem 2rem;
  width: 110rem;
  margin: 0 auto;
}

@media (max-width: 1150px) {
  .team-container {
    grid-template-columns: 1fr 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (max-width: 800px) {
  .team-container {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.team-container__box {
  width: 33rem;
  background-color: white;
  box-shadow: 0px 20px 10px 0px rgba(0, 0, 0, 0.08);
}

@media (max-width: 400px) {
  .team-container__box {
    width: 100%;
  }
}

.team-container__box__img-div {
  width: 100%;
  height: auto;
  background-color: #f6f6f6;
}

.team-container__box__img-div img {
  width: 100%;
}

.team-container__box__descr {
  color: #010103;
  padding: 3rem;
}

.team-container__box__descr h3 {
  font-size: 2.2rem;
}

.team-container__box__descr p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #777777;
}

.contact-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin: 0 auto;
  color: #010103;
  padding: 10rem 2rem;
  background-image: url("/src/images/banners/bg-contact.png");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 950px) {
  .contact-div {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.contact-div__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 41rem;
}

@media (max-width: 950px) {
  .contact-div__text {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}

.contact-div__text h2 {
  font-size: 4.2rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.contact-div__text p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.contact-div__text a {
  text-decoration: none;
  color: #010103;
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.2s;
  margin-bottom: 0.5rem;
}

.contact-div__text a:hover {
  color: #96040e;
}

.contact-div__form {
  display: flex;
  flex-direction: column;
}

.contact-div__form form {
  display: flex;
  flex-direction: column;
}

.contact-div__form form label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact-div__form form label b {
  color: #96040e;
}

.contact-div__form form input {
  background-color: #f2f2f2;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.3rem;
}

.contact-div__form form textarea {
  background-color: #f2f2f2;
  height: 18rem;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.5rem;
}

.contact-div__form form button {
  background-color: #96040e;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.35);
  transition: all 0.3s;
  border: 2px solid #96040e;
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
}

.contact-div__form form button:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.6);
  background-color: #fa4226;
}

/*# sourceMappingURL=styles.css.map */
 @media (max-width: 800px) {

.bg-shape{
  display: block !important;
}
.hero-content__car-img{
  display: block !important;
  height: 210px !important;
    width: 385px !important;
}
 }
 .plan-container__boxes__box{
  padding: 0rem 2rem !important;
}
.plan-container__boxes{
   padding: 0rem 0rem !important;

 }
 footer {
  background-color: #ffffff;
  padding: 1rem 0;
}



.dropdown1234567 {
  color: black !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-weight: 700 !important;
  /* float: left;
  overflow: hidden; */
}

/* Dropdown123456 button */
.dropdown1234567 .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: rgb(0, 0, 0);
  font-weight: bold;
  padding: 0px 0px 0px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
  width: -webkit-fill-available;
  font-family: "Rubik", sans-serif;
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown1234567:hover .dropbtn {
  /* background-color: red; */
}

/* Dropdown123456 content (hidden by default) */
.dropdown1234567-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 9999;
  top: 55%;

}

/* Links inside the dropdown1234567 */
.dropdown1234567-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown1234567 links on hover */
.dropdown1234567-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown1234567 menu on hover */
.dropdown1234567:hover .dropdown1234567-content {
  display: block;
}












/* <Link to="/budget-friendly-car-rental-in-karama-by-quick-lease">
    Budget Friendly Car Rental In Karama By Quick Lease
</Link>

<Link to="/our-car-rental-fleet-per-day-aed-100-per-week-aed-1000-per-month-aed-1536">
    Our Car Rental Fleet Per Day AED 100 Per Week AED 1000 Per Month AED 1536
</Link>

<Link to="/rent-a-car-karama-hire-quality-cars-at-cost-effective">
    Rent A Car Karama Hire Quality Cars At Cost Effective
</Link>

<Link to="/car-rental-in-al-karama-hire-cheap-car-in-al-karama">
    Car Rental In Al Karama Hire Cheap Car In Al Karama
</Link>

<Link to="/rent-a-car-in-karama-in-uae-on-the-map-celia-car-rental">
    Rent A Car In Karama In UAE On The Map Celia Car Rental
</Link>

<Link to="/rent-a-car-in-al-karama-with-great-rent-a-car">
    Rent A Car In Al Karama With Great Rent A Car
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-cheap-car-hire-dubai">
    Rent A Car In Al Karama Dubai Cheap Car Hire Dubai
</Link>

<Link to="/cheap-car-rental-al-karama-rent-a-cars-royal-dubai-uae">
    Cheap Car Rental Al Karama Rent A Cars Royal Dubai UAE
</Link>

<Link to="/car-rental-karama-dubai-united-arab-emirates">
    Car Rental Karama Dubai United Arab Emirates
</Link>

<Link to="/rent-a-car-karama-dubai-daily-and-long-term-car-hire-al-karama">
    Rent A Car Karama Dubai Daily And Long Term Car Hire Al Karama
</Link>

<Link to="/luxury-car-rental-al-karama-rent-a-car-royal-dubai-uae">
    Luxury Car Rental Al Karama Rent A Car Royal Dubai UAE
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-best-deals-and-offers">
    Rent A Car In Al Karama Dubai Best Deals And Offers
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-affordable-reliable">
    Rent A Car In Al Karama Dubai Affordable Reliable
</Link>

<Link to="/do-you-reside-in-al-karama-and-wish-to-hire-a-car-to-make-your-travels-easier?">
    Do You Reside In Al Karama And Wish To Hire A Car To Make Your Travels Easier?
</Link>

<Link to="/best-deals-on-car-rentals-in-al-karama-dubai-daily-weekly-and-monthly-rates-available-book-now!">
    Best Deals On Car Rentals In Al Karama Dubai Daily Weekly And Monthly Rates Available Book Now!
</Link>

<Link to="/helps-you-to-find-perfect-car-for-your-next-ride-with-rent-a-car-dubai-in-al-karama">
    Helps You To Find Perfect Car For Your Next Ride With Rent A Car Dubai In Al Karama
</Link>

<Link to="/rent-a-car-at-cheap-price-in-al-karama">
    Rent A Car At Cheap Price In Al Karama
</Link>

<Link to="/rent-a-car-has-a-unique-list-of-cars-available-for-rent-in-al-karama-dubai!">
    Rent A Car Has A Unique List Of Cars Available For Rent In Al Karama Dubai!
</Link>

<Link to="/cheap-car-rental-in-al-karama-dubai-uae-price-from-aed-90-per-day">
    Cheap Car Rental In Al Karama Dubai UAE Price From AED 90 Per Day
</Link>

<Link to="/find-cheap-car-rental-deals-for-karama-at-falcon-rides-com">
    Find Cheap Car Rental Deals For Karama At Falcon Rides Com
</Link>

<Link to="/looking-for-a-car-rental-company-in-karama-dubai">
    Looking For A Car Rental Company In Karama Dubai
</Link>

<Link to="/rent-a-car-in-al-karama-dubai-discover-the-vibrant-neighborhood-of-al-karama-with-our-affordable-car-rental-options">
    Rent A Car In Al Karama Dubai Discover The Vibrant Neighborhood Of Al Karama With Our Affordable Car Rental Options
</Link>

<Link to="/the-cost-of-renting-a-car-in-al-karama-varies-depending-on-the-type-of-vehicle-you-choose-and-the-rental-duration">
    The Cost Of Renting A Car In Al Karama Varies Depending On The Type Of Vehicle You Choose And The Rental Duration
</Link>

<Link to="/renting-a-car-in-al-karama-with-car-rental-dbx-is-not-only-convenient-but-also-remarkably-affordable">
    Renting A Car In Al Karama With Car Rental DBX Is Not Only Convenient But Also Remarkably Affordable
</Link>

      








        <Link to="/rent-a-car-in-dubai-online-at-affordable-prices">
Rent a Car in Dubai Online at Affordable Prices
</Link>

<Link to="/rent-a-car-dubai-economy-and-luxury-car-rental-uae">
Rent a Car Dubai | Economy and Luxury Car Rental UAE
</Link>

<Link to="/self-drive-cars-in-dubai-car-rental-services">
Self Drive Cars in Dubai | Car Rental Services
</Link>

<Link to="/dubai-international-airport-term-1-car-rental">
dubai international airport term 1 - Car Rental
</Link>

<Link to="/rent-a-car-cheap-car-rental-car-hire-in-dubai-uae">
Rent a Car | Cheap Car Rental | Car Hire in Dubai UAE
</Link>

<Link to="/car-rental-dubai-search-cheap-rental-car-deals">
Car Rental Dubai – Search Cheap Rental Car Deals
</Link>

<Link to="/cheap-car-rental-in-dubai-find-and-compare-deals">
Cheap car rental in Dubai: Find and compare deals
</Link>

<Link to="/weekly-car-rental-in-dubai-rent-a-car-with-no-hidden-charges">
Weekly Car Rental In Dubai - Rent A Car With No Hidden Charges
</Link>

<Link to="/car-rental-rent-a-car-dubai-at-lowest-price">
Car Rental | Rent a Car Dubai at lowest price
</Link>

<Link to="/rent-a-car-in-dubai-car-rental-agency-in-uae">
Rent A Car In Dubai - Car Rental Agency in UAE
</Link>

<Link to="/rent-a-car-in-dubai-car-rental-dubai">
Rent a Car in Dubai - Car Rental Dubai
</Link>

<Link to="/rent-a-car-dubai-best-car-rental-offers-monthly-and-daily">
Rent a car Dubai | Best car rental offers Monthly and Daily
</Link>

<Link to="/rent-a-car-al-karama-cheap-car-rental-hire-in-dubai">
Rent a car Al Karama, Cheap Car Rental & Hire in Dubai
</Link>

<Link to="/rent-a-car-karama-at-cheapest-rates-falcon-rides-car-rental-dubai">
Rent A Car Karama At Cheapest Rates - Falcon Rides Car Rental Dubai
</Link>

<Link to="/affordable-car-rental-al-karama-dubai">
Affordable Car Rental Al Karama - Dubai
</Link>

        <div className="plan-container__boxes">
          <Link to="/rent-a-car-UAE">
            Rent a Car UAE
           
          </Link>
         
          <Link to="/rent-a-car-dubai">
            Rent a Car Dubai
           
          </Link>
          <Link to="/rent-a-car-al-karama">
            Rent a Car Al Karama Dubai
           
          </Link>

          <Link to="/rent-a-car-al-quoz">

            Rent a Car Al Quoz
            
          </Link>
          <Link to="/rent-a-car-al-Satwa">

            Rent a Car Al Satwa
           
          </Link>
          <Link to="/rent-a-car-burj-al-arab">
            Rent a Car Burj Al Arab
           
          </Link>

          <Link to="/rent-a-car-burj-khalifa">

            Rent a Car Burj Khalifa
            
          </Link>
          <Link to="/rent-a-car-dubai-frame">

            Rent a Car Dubai Frame
           
          </Link>
          <Link to="/rent-a-car-dubai-marina">
            Rent a Car Dubai Marina
           
          </Link>

          <Link to="/rent-a-car-emirates-hills">

            Rent a Car Emirates Hills
            
          </Link>
          <Link to="/rent-a-car-jumeirah-beach">

            Rent a Car Jumeirah Beach
           
          </Link>
          <Link to="/rent-a-car-palm-jumeirah">

            Rent a Car Palm Jumeirah
           
          </Link>
          <Link to="/rent-a-car-dubai-downtown">

            Rent a Car Dubai Downtown
            
          </Link>
          <Link to="/rent-a-car-Deira">

            Rent a Car Deira
           
          </Link>
          <Link to="/rent-a-car-dubai-hills">

            Rent a Car Dubai Hills
           
          </Link>
          <Link to="/rent-a-car-sheikh-zayed-road">

            Rent a Car Sheikh Zayed Road
           
          </Link>
          <Link to="/rent-a-car-dubai-silicon-oasis">

            Rent a Car Dubai Silicon Oasis
           
          </Link>
          <Link to="/rent-a-car-Abu-Dhabi">

            Rent a Car Abu Dhabi
           
          </Link>
          <Link to="/rent-a-car-Ajman">

            Rent a Car Ajman
           
          </Link>
          <Link to="/rent-a-car-Dubai-Fountain">

            Rent a Car Dubai Fountain
           
          </Link>
          <Link to="/rent-a-car-Ski-Dubai">

            Rent a Car Ski Dubai
           
          </Link>
          
          <Link to="/rent-a-car-Dubai-Miracle-Garden">

            Rent a Car Dubai Miracle Garden
           
          </Link>
          
          <Link to="/rent-a-car-Sharjah">

            Rent a Car Sharjah
           
          </Link>
          
          <Link to="/rent-a-car-Fujairah">

            Rent a Car Fujairah
           
          </Link>
          
          <Link to="/rent-a-car-Al-Ain">

            Rent a Car Al Ain
           
          </Link>
          
          <Link to="/rent-a-car-Desert-Safari">

            Rent a Car Desert Safari
           
          </Link>
          
          <Link to="/rent-a-car-Ras-Al-Khaimah">

            Rent a Car Ras Al Khaimah
           
          </Link>
          
          <Link to="/rent-a-car-Umm-Al-Quwain">

            Rent a Car Umm Al Quwain
           
          </Link>
          
          <Link to="/rent-a-car-One-&-Only-One-Za'abeel">

            Rent a Car One & Only One Za'abeel
           
          </Link>
          






















          <Link to="/rent-a-car-service-in-UAE">
            Rent a Car Service in UAE
           
          </Link>
          <Link to="/rent-a-car-in-UAE">
            Rent a Car Service in UAE
           
          </Link>
          <Link to="/rent-a-car-dubai">
            Rent a Car Dubai
           
          </Link>
          <Link to="/rent-a-car-service-in-al-karama">
            Rent a Car Service in Al Karama Dubai
           
          </Link>

          <Link to="/rent-a-car-service-in-al-quoz">

            Rent a Car Service in Al Quoz
            
          </Link>
          <Link to="/rent-a-car-service-in-al-Satwa">

            Rent a Car Service in Al Satwa
           
          </Link>
          <Link to="/rent-a-car-service-in-burj-al-arab">
            Rent a Car Service in Burj Al Arab
           
          </Link>

          <Link to="/rent-a-car-service-in-burj-khalifa">

            Rent a Car Service in Burj Khalifa
            
          </Link>
          <Link to="/rent-a-car-service-in-dubai-frame">

            Rent a Car Service in Dubai Frame
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-marina">
            Rent a Car Service in Dubai Marina
           
          </Link>

          <Link to="/rent-a-car-service-in-emirates-hills">

            Rent a Car Service in Emirates Hills
            
          </Link>
          <Link to="/rent-a-car-service-in-jumeirah-beach">

            Rent a Car Service in Jumeirah Beach
           
          </Link>
          <Link to="/rent-a-car-service-in-palm-jumeirah">

            Rent a Car Service in Palm Jumeirah
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-downtown">

            Rent a Car Service in Dubai Downtown
            
          </Link>
          <Link to="/rent-a-car-service-in-Deira">

            Rent a Car Service in Deira
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-hills">

            Rent a Car Service in Dubai Hills
           
          </Link>
          <Link to="/rent-a-car-service-in-sheikh-zayed-road">

            Rent a Car Service in Sheikh Zayed Road
           
          </Link>
          <Link to="/rent-a-car-service-in-dubai-silicon-oasis">

            Rent a Car Service in Dubai Silicon Oasis
           
          </Link>
          <Link to="/rent-a-car-service-in-Abu-Dhabi">

            Rent a Car Service in Abu Dhabi
           
          </Link>
          <Link to="/rent-a-car-service-in-Ajman">

            Rent a Car Service in Ajman
           
          </Link>
          <Link to="/rent-a-car-service-in-Dubai-Fountain">

            Rent a Car Service in Dubai Fountain
           
          </Link>
          <Link to="/rent-a-car-service-in-Ski-Dubai">

            Rent a Car Service in Ski Dubai
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Dubai-Miracle-Garden">

            Rent a Car Service in Dubai Miracle Garden
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Sharjah">

            Rent a Car Service in Sharjah
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Fujairah">

            Rent a Car Service in Fujairah
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Al-Ain">

            Rent a Car Service in Al Ain
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Desert-Safari">

            Rent a Car Service in Desert Safari
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Ras-Al-Khaimah">

            Rent a Car Service in Ras Al Khaimah
           
          </Link>
          
          <Link to="/rent-a-car-service-in-Umm-Al-Quwain">

            Rent a Car Service in Umm Al Quwain
           
          </Link>
          
          <Link to="/rent-a-car-service-in-One-&-Only-One-Za'abeel">

            Rent a Car Service in One & Only One Za'abeel
           
          </Link>
          
























          <Link to="/car-rental-service-in-UAE">
            Car Rental Service in UAE
           
          </Link>
          <Link to="/car-rental-UAE">
            Car Rental Service in UAE
           
          </Link>
          <Link to="/carrental-dubai">
            Car Rental Dubai
           
          </Link>
          <Link to="/car-rental-service-in-al-karama">
            Car Rental Service in Al Karama Dubai
           
          </Link>

          <Link to="/car-rental-service-in-al-quoz">

            Car Rental Service in Al Quoz
            
          </Link>
          <Link to="/car-rental-service-in-al-Satwa">

            Car Rental Service in Al Satwa
           
          </Link>
          <Link to="/car-rental-service-in-burj-al-arab">
            Car Rental Service in Burj Al Arab
           
          </Link>

          <Link to="/car-rental-service-in-burj-khalifa">

            Car Rental Service in Burj Khalifa
            
          </Link>
          <Link to="/car-rental-service-in-dubai-frame">

            Car Rental Service in Dubai Frame
           
          </Link>
          <Link to="/car-rental-service-in-dubai-marina">
            Car Rental Service in Dubai Marina
           
          </Link>

          <Link to="/car-rental-service-in-emirates-hills">

            Car Rental Service in Emirates Hills
            
          </Link>
          <Link to="/car-rental-service-in-jumeirah-beach">

            Car Rental Service in Jumeirah Beach
           
          </Link>
          <Link to="/car-rental-service-in-palm-jumeirah">

            Car Rental Service in Palm Jumeirah
           
          </Link>
          <Link to="/car-rental-service-in-dubai-downtown">

            Car Rental Service in Dubai Downtown
            
          </Link>
          <Link to="/car-rental-service-in-Deira">

            Car Rental Service in Deira
           
          </Link>
          <Link to="/car-rental-service-in-dubai-hills">

            Car Rental Service in Dubai Hills
           
          </Link>
          <Link to="/car-rental-service-in-sheikh-zayed-road">

            Car Rental Service in Sheikh Zayed Road
           
          </Link>
          <Link to="/car-rental-service-in-dubai-silicon-oasis">

            Car Rental Service in Dubai Silicon Oasis
           
          </Link>
          <Link to="/car-rental-service-in-Abu-Dhabi">

            Car Rental Service in Abu Dhabi
           
          </Link>
          <Link to="/car-rental-service-in-Ajman">

            Car Rental Service in Ajman
           
          </Link>
          <Link to="/car-rental-service-in-Dubai-Fountain">

            Car Rental Service in Dubai Fountain
           
          </Link>
          <Link to="/car-rental-service-in-Ski-Dubai">

            Car Rental Service in Ski Dubai
           
          </Link>
          
          <Link to="/car-rental-service-in-Dubai-Miracle-Garden">

            Car Rental Service in Dubai Miracle Garden
           
          </Link>
          
          <Link to="/car-rental-service-in-Sharjah">

            Car Rental Service in Sharjah
           
          </Link>
          
          <Link to="/car-rental-service-in-Fujairah">

            Car Rental Service in Fujairah
           
          </Link>
          
          <Link to="/car-rental-service-in-Al-Ain">

            Car Rental Service in Al Ain
           
          </Link>
          
          <Link to="/car-rental-service-in-Desert-Safari">

            Car Rental Service in Desert Safari
           
          </Link>
          
          <Link to="/car-rental-service-in-Ras-Al-Khaimah">

            Car Rental Service in Ras Al Khaimah
           
          </Link>
          
          <Link to="/car-rental-service-in-Umm-Al-Quwain">

            Car Rental Service in Umm Al Quwain
           
          </Link>
          
          <Link to="/car-rental-service-in-One-&-Only-One-Za'abeel">

            Car Rental Service in One & Only One Za'abeel
           
          </Link>
          
























          <Link to="/rent-a-car-deira-cheap-car-rental-hire-in-dubai-uae">
Rent a car Deira, Cheap Car Rental & Hire in Dubai, UAE
</Link>

<Link to="/low-cost-car-rental-deira-dubai">
Low-Cost Car Rental Deira - Dubai
</Link>

<Link to="/car-rentals-in-deira-for-aed-75-day">
Car Rentals In Deira For AED 75/Day
</Link>

<Link to="/rent-a-car-deira-budget-car-rentals-deira">
Rent a Car Deira | Budget Car Rentals Deira
</Link>

<Link to="/car-rentals-in-deira-dubai-falcon-rides">
Car Rentals in Deira (Dubai) - Falcon Rides
</Link>

<Link to="/rent-a-car-in-deira-dubai">
Rent a Car in Deira Dubai
</Link>

<Link to="/cheap-car-hire-in-deira-dubai-from-20-day">
Cheap car hire in Deira, Dubai from £20/day
</Link>

<Link to="/rent-a-car-deira-car-hire-available-for-aed-72-day">
Rent a Car Deira | Car Hire Available for AED 72/Day
</Link>

<Link to="/car-rental-from-deira-city-center-dubai">
Car Rental from Deira City Center - Dubai
</Link>

<Link to="/rent-a-car-in-dubai-deira-cheap-car-rental-and-hire-in-deira">
Rent a Car in Dubai Deira | Cheap Car rental and hire in Deira
</Link>

<Link to="/cheap-rent-a-car-deira-dubai">
Cheap Rent A Car Deira Dubai
</Link>

<Link to="/best-car-rent-in-deira-hire-self-drive-cars-deposit-free">
Best Car Rent in Diera - Hire Self Drive Cars Deposit Free
</Link>




<Link to="/rent-a-car-jumeirah-beach-residence">
Rent a Car Jumeirah Beach Residence
</Link>

<Link to="/car-rental-jumeirah-dubai">
Car Rental Jumeirah Dubai
</Link>

<Link to="/car-rentals-in-jumeirah-dubai-from-34-day">
Car Rentals in Jumeirah (Dubai) from $34/day
</Link>

<Link to="/rent-a-car-at-jumeirah-beach-residence">
Rent a Car at Jumeirah Beach Residence
</Link>

<Link to="/rent-a-car-in-jumeirah-village-circle-dubai">
Rent a Car in Jumeirah Village Circle, Dubai
</Link>

<Link to="/rent-a-car-jumeirah-beach-residence-dubai">
Rent A Car Jumeirah Beach Residence - Dubai
</Link>

<Link to="/pearl-jumeirah-rent-a-car-dubai-from-aed-40-day">
Pearl Jumeirah Rent A Car Dubai From AED 40/Day
</Link>

<Link to="/car-rental-in-palm-jumeirah-dubai">
Car rental in Palm Jumeirah - Dubai
</Link>

<Link to="/cheap-car-hire-in-jumeirah-beach-residence">
Cheap Car Hire in Jumeirah Beach Residence
</Link>

<Link to="/rent-a-car-in-jumeirah-village-circle-jvc-dubai">
Rent a Car in Jumeirah Village Circle (JVC) - Dubai
</Link>

<Link to="/car-rental-in-jumeirah-dubai">
Car Rental in Jumeirah, Dubai
</Link>

<Link to="/cheap-car-rentals-in-jumeirah-from-just-32">
Cheap Car Rentals in Jumeirah from just $32
</Link>

<Link to="/best-car-rent-in-jumeirah-hire-self-drive-cars">
Best Car Rent in Jumeirah - Hire Self Drive Cars
</Link>

<Link to="/car-rental-deals-at-hilton-dubai-jumeirah">
Car Rental Deals at Hilton Dubai Jumeirah
</Link>

<Link to="/cheap-car-hire-in-jumeirah-dubai-from-31-day">
Cheap car hire in Jumeirah, Dubai from £31/day
</Link>

<Link to="/weekly-car-rental-jumeirah-1-dubai">
Weekly Car Rental Jumeirah 1 - Dubai
</Link>

<Link to="/cheap-rental-cars-jumeirah-beach-residence-book-now">
Cheap Rental Cars Jumeirah Beach Residence Book Now!
</Link>

<Link to="/rent-a-car-in-palm-jumeirah">
Rent a Car in Palm Jumeirah
</Link>






<Link to="/car-hire-in-al-quoz-dubai-from-aed-157-day">
Car Hire in Al Quoz (Dubai) from AED 157/day
</Link>

<Link to="/rent-a-car-al-quoz-best-car-rental-offers-al-quoz">
Rent a Car Al Quoz | Best Car Rental Offers Al Quoz
</Link>

<Link to="/cost-effective-car-rental-al-quoz-dubai">
Cost-Effective Car Rental Al Quoz - Dubai
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai-from-109-aed">
Rent A Car In Al Quoz Dubai From 109 AED
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai">
Rent a Car in Al Quoz, Dubai
</Link>

<Link to="/rent-a-car-al-quoz-cheap-car-rentals-near-me">
Rent A Car Al Quoz | Cheap Car Rentals Near Me
</Link>

<Link to="/car-rentals-in-al-quoz-dubai-from-36-day">
Car Rentals in Al Quoz (Dubai) from $36/day
</Link>

<Link to="/car-rental-in-dubai-al-quoz-branch">
Car rental in Dubai Al Quoze Branch
</Link>

<Link to="/rent-car-al-quoz-dubai">
Rent car Al Quoz - Dubai
</Link>

<Link to="/cheap-car-rentals-in-al-quoz-dubai-from-45-day">
Cheap Car Rentals in Al Quoz, Dubai from $45/day
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai">
Rent a Car in Al Quoz - Dubai
</Link>

<Link to="/rent-a-car-al-quoz-find-special-car-hire-discounts">
Rent a Car Al Quoz | Find Special Car Hire Discounts
</Link>

<Link to="/hire-car-al-quoz-dubai">
Hire Car Al Quoz - Dubai
</Link>

<Link to="/best-car-rent-in-al-quoz-dubai">
Best Car Rent in Al Quoz Dubai
</Link>

<Link to="/car-rental-dubai-al-quoz-uae-special-discounts-offers">
Car Rental Dubai Al Quoz, UAE - Special Discounts & Offers
</Link>

<Link to="/affordable-car-rental-services-near-al-quoz-mall">
Affordable Car Rental Services Near Al Quoz Mall
</Link>

<Link to="/rent-a-car-in-al-quoz-mall-dubai-best-deals-and-offers">
Rent a Car in Al Quoz Mall, Dubai - Best Deals and Offers
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai">
Rent a Car in Al Quoz, Dubai
</Link>

<Link to="/car-rental-companies-dubai-rent-a-luxury-car-with-driver">
Car Rental Companies Dubai - Rent a Luxury Car with Driver
</Link>

<Link to="/rent-a-car-al-quoz-mall-best-deals-offers">
Rent A Car Al Quoz Mall | Best Deals & Offers
</Link>

<Link to="/car-rentals-in-al-quoz-dubai">
Car rentals in Al Quoz, Dubai
</Link>

<Link to="/rent-a-car-in-al-quoz-dubai-cheap-car-hire">
Rent a Car in Al Quoz, Dubai, Cheap Car Hire
</Link>

<Link to="/rent-a-car-dubai-best-car-rental-services">
Rent a Car Dubai | Best Car Rental Services
</Link>

<Link to="/car-hire-leasing-al-quoz">
Car Hire & Leasing Al Quoz
</Link>

<Link to="/car-rental-al-quoz-rent-a-car-dubai-at-lowest-price">
Car Rental Al Quoz | Rent a Car Dubai at lowest price
</Link>


<Link to="/car-rentals-in-ras-al-khor-dubai-falcon-rides">
Car rentals in Ras Al Khor, Dubai - Falcon Rides
</Link>

<Link to="/ras-al-khor-rent-a-car-dubai-with-falcon-rides">
Ras Al Khor Rent A Car Dubai With Falcon Rides
</Link>

<Link to="/rent-a-car-ras-al-khor-travel-with-ease-quick-lease">
Rent a car Ras Al Khor | Travel with ease | Quick Lease
</Link>

<Link to="/rent-a-car-in-ras-al-khor-dubai-best-deals-and-offers">
Rent a Car in Ras Al Khor, Dubai - Best Deals and Offers
</Link>

<Link to="/premium-car-rental-service-ras-al-khor-dubai">
Premium Car Rental Service Ras Al Khor - Dubai
</Link>

<Link to="/21-dollar-car-rental-ras-al-khor-industrial-area">
$21 Car Rental Ras Al Khor Industrial Area
</Link>

<Link to="/rent-a-car-in-ras-al-khor-dubai">
Rent a car in Ras Al Khor - Dubai
</Link>

<Link to="/rent-a-car-in-ras-al-khor">
Rent a car in Ras Al Khor
</Link>

<Link to="/rent-a-car-ras-al-khor-dubai">
Rent a Car Ras Al Khor - Dubai
</Link>

<Link to="/rent-a-car-ras-al-khor-travel-with-ease">
Rent a car Ras Al Khor | Travel with ease
</Link>

<Link to="/rent-a-car-in-ras-al-khor-car-rental-in-dubai">
Rent a Car in Ras al Khor - car rental in Dubai
</Link>

<Link to="/cheap-rental-cars-ras-al-khor-industrial-area-book-now">
Cheap Rental Cars Ras Al Khor Industrial Area Book Now!
</Link>

<Link to="/car-rent-in-ras-al-khor-under-140-aed-with-great-dubai">
Car Rent in Ras Al Khor Under 140 AED With Great Dubai
</Link>

<Link to="/rent-a-car-cheap-car-rental-hire-in-ras-al-khor-uae">
Rent a car, Cheap Car Rental & Hire in Ras Al Khor, UAE
</Link>

<Link to="/best-rent-a-car-ras-al-khor">
Best Rent a Car – Ras Al Khor
</Link>

<Link to="/book-a-rent-a-car-in-dubai-weekly-monthly-cheap">
Book a Rent a Car in Dubai | Weekly, Monthly Cheap
</Link>

<Link to="/rent-a-car-in-dubai-ras-al-khor">
Rent a Car in Dubai Ras Al Khor
</Link>

<Link to="/low-cost-car-rental-deals-in-dubai-ras-al-khor-uae">
Low-cost Car Rental Deals in Dubai Ras Al Khor, UAE 🔥
</Link>

<Link to="/budget-car-rental-ras-al-khor-hire-economy-cars-for-rent-in-dubai">
Budget Car Rental Ras al Khor Hire Economy Cars for Rent in Dubai
</Link>

<Link to="/car-hire-leasing-ras-al-khor">
Car Hire & Leasing Ras Al Khor
</Link>

<Link to="/cheap-car-hire-ras-al-khor-dubai">
Cheap Car Hire Ras Al Khor Dubai
</Link>

<Link to="/car-rental-ras-al-khor-in-uae-on-the-map">
Car rental ras al khor in UAE on the map
</Link>

<Link to="/pickup-rental-cars-ras-al-khor-industrial-area">
Pickup Rental Cars Ras Al Khor Industrial Area
</Link>

<Link to="/rent-a-car-in-ras-al-khor-dubai">
Rent a Car in Ras Al Khor, Dubai
</Link>

<Link to="/car-rental-car-rentals-in-ras-al-khor-industrial-area">
Car Rental Car Rentals in Ras Al Khor Industrial Area
</Link>

<Link to="/autorent-car-rental-in-dubai-ras-al-khor-uae">
Autorent Car Rental in Dubai Ras Al Khor, UAE
</Link>

<Link to="/dollar-rent-a-car-car-rentals-in-ras-al-khor-industrial-area">
Dollar Rent A Car Car Rentals in Ras Al Khor Industrial Area
</Link>

<Link to="/car-rentals-in-ras-al-khor-dubai">
Car rentals in Ras Al Khor Dubai
</Link>

<Link to="/cheap-car-rental-deals-in-ras-al-khor-dubai">
Cheap Car Rental Deals in Ras Al Khor Dubai
</Link>

<Link to="/car-hire-ras-al-khor-dubai">
Car Hire Ras Al Khor - Dubai
</Link>

<Link to="/best-luxury-car-rental-in-dubai-uae">
Best Luxury Car Rental in Dubai (UAE)
</Link>

<Link to="/cheap-car-hire-in-zaabeel-dubai-from-77-pound-day">
Cheap car hire in Za'abeel, Dubai from £77/day
</Link>

<Link to="/car-rentals-in-zaabeel-dubai-uae">
Car rentals in Za'abeel, Dubai UAE
</Link>

<Link to="/car-rental-in-zaabeel-dubai">
Car Rental In Za'abeel - Dubai
</Link>

<Link to="/rent-a-car-in-zaabeel-dubai-cheap-car-hire">
Rent a Car in Za'abeel, Dubai, Cheap Car Hire
</Link>



<Link to="/dubai-festival-city-car-rental-hire-online">
Dubai Festival City Car Rental & Hire Online
</Link>

<Link to="/car-rental-dubai-festival-city">
Car Rental Dubai Festival City
</Link>

<Link to="/21-dollar-car-rental-dubai-festival-city-mall">
$21 Car Rental Dubai Festival City Mall
</Link>

<Link to="/long-term-car-rental-dubai-festival-city">
Long Term Car Rental Dubai Festival City
</Link>

<Link to="/festival-city-rent-a-car-cheap-car-rentals">
Festival City Rent A Car | Cheap Car Rentals
</Link>

<Link to="/rent-a-car-dubai-festival-city-falcon-rides-car-rental">
Rent A Car Dubai Festival City - Falcon Rides Car Rental
</Link>

<Link to="/car-rental-in-dubai-festival-city">
Car Rental in Dubai Festival City
</Link>

<Link to="/car-rental-dubai-festival-city">
Car rental in Dubai Festival City
</Link>

<Link to="/81-dollar-car-rental-dubai-festival-city">
$81 Car Rental Dubai Festival City
</Link>

<Link to="/car-rental-dubai-festival-city-united-arab-emirates">
Car Rental Dubai Festival City - United Arab Emirates
</Link>

<Link to="/rent-a-car-in-dubai-festival-city">
Rent a Car in Dubai Festival City
</Link>

<Link to="/rent-a-car-in-dubai-festival-city-mall">
Rent a Car in Dubai Festival City Mall
</Link>

<Link to="/cheap-rental-cars-dubai-festival-city-book-now">
Cheap Rental Cars Dubai Festival City Book Now!
</Link>

<Link to="/affordable-car-rent-in-dubai-festival-city-with-great-dubai">
Affordable Car Rent in Dubai Festival City with Great Dubai.
</Link>

<Link to="/rent-a-car-dubai-festival-city-online-reservation">
Rent a car Dubai Festival City - Online reservation
</Link>

<Link to="/easy-and-economical-car-rental-festival-city-dubai">
Easy and economical car rental festival city Dubai
</Link>

<Link to="/rent-a-car-dubai-festival-city">
Rent a Car Dubai Festival City
</Link>





<Link to="/rent-a-car-bur-dubai-cheap-car-rental-hire-in-bur-dubai">
Rent a car Bur Dubai, Cheap Car Rental & Hire in Bur Dubai
</Link>

<Link to="/rent-a-car-bur-dubai-for-75-day">
Rent A Car Bur Dubai For $75/Day
</Link>

<Link to="/rent-a-car-bur-dubai-cheap-car-rental-bur-dubai">
Rent a Car Bur Dubai | Cheap Car Rental Bur Dubai
</Link>

<Link to="/long-term-car-rentals-bur-dubai">
Long-Term Car Rentals Bur Dubai
</Link>

<Link to="/car-hire-in-bur-dubai-search-hire-cars">
Car Hire in Bur Dubai (Dubai) - Search Hire Cars
</Link>

<Link to="/rent-a-car-bur-dubai-affordable-car-rental">
Rent A Car Bur Dubai | Affordable Car Rental
</Link>

<Link to="/bur-dubai-rent-a-car-in-dubai">
Bur Dubai - Rent a Car in Dubai
</Link>

<Link to="/car-rental-dubai-search-cheap-rental-car-deals">
Car Rental Dubai – Search Cheap Rental Car Deals
</Link>

<Link to="/cheap-car-rental-bur-dubai-rent-a-cars-royal-uae">
Cheap car rental Bur Dubai, rent a cars Royal: UAE
</Link>

<Link to="/rent-a-car-in-bur-dubai">
Rent a Car in Bur Dubai
</Link>

<Link to="/car-rental-in-bur-dubai-car-rental-near-me-on-the-map">
Car rental in Bur Dubai, car rental near me on the map
</Link>

<Link to="/car-rental-bur-dubai-cheap-rental-cars">
Car Rental Bur Dubai - Cheap Rental Cars
</Link>

<Link to="/weekly-car-hire-book-your-car-dubai-rental-deals-call-now">
Weekly Car Hire-book your car | Dubai Rental Deals - Call Now
</Link>

<Link to="/rental-cars-in-bur-dubai-in-uae-on-the-map">
Rental cars in Bur Dubai in UAE on the map
</Link>

<Link to="/rent-a-car-bur-dubai-experience-the-best-car">
Rent A Car Bur Dubai (Experience The Best Car)
</Link>

<Link to="/cheap-rent-a-car-in-bur-dubai">
Cheap Rent a Car in Bur Dubai
</Link>

<Link to="/rent-a-car-in-dubai-cheapest-monthly-car-rental">
Rent a Car in Dubai, Cheapest Monthly Car Rental
</Link>

<Link to="/rent-a-car-in-bur-dubai-cheapest-monthly-car-rental">
Rent a Car in Bur Dubai, Cheapest Monthly Car Rental
</Link>

<Link to="/low-cost-car-rental-deals-in-bur-dubai-uae">
Low-cost Car Rental Deals in Bur Dubai, UAE 🔥
</Link>

<Link to="/luxury-car-rental-in-bur-dubai-make-my-ride">
Luxury Car Rental in Bur Dubai | Make My Ride
</Link>

<Link to="/rent-a-car-per-hour-in-dubai-hourly-car-rental">
Rent a car per hour in Dubai | Hourly car rental
</Link>

<Link to="/cheap-rent-a-car-in-bur-dubai-cheapest-as-150-dhs-book">
Cheap Rent A Car In Bur Dubai - Cheapest As 150 Dhs Book
</Link>

<Link to="/monthly-car-rental-in-bur-dubai">
Monthly Car Rental in Bur Dubai
</Link>





<Link to="/car-rental-dubai-dubai-car-rental">
Car rental Dubai - Dubai car rental
</Link>

<Link to="/car-rental-companies-dubai">
Car Rental Companies Dubai
</Link>

<Link to="/fast-rent-a-car-in-dubai-car-rental-dubai-aed-150-day">
Fast Rent a Car In Dubai | Car Rental Dubai AED 150/Day
</Link>

<Link to="/dubai-leading-car-rental-rent-a-car-in-dubai">
Dubai's Leading Car Rental | Rent a Car in Dubai
</Link>

<Link to="/dubai-internaional-airport-terminal-1-dxb-car-rental">
Dubai Intl. Airport Terminal 1 (DXB) Car Rental
</Link>

<Link to="/renting-a-car-ministry-of-economy-uae">
Renting a Car | Ministry of Economy - UAE
</Link>

<Link to="/best-rent-a-car-in-dubai-economy-car-rental-dubai">
Best Rent A Car In Dubai | Economy Car Rental Dubai
</Link>

<Link to="/rent-a-car-in-dubai-dubai-car-rental-hire-car-in-dubai">
Rent a Car in Dubai | Dubai Car Rental | Hire Car in Dubai
</Link>

<Link to="/car-hire-in-dubai-cheap-rental-deals">
Car Hire in Dubai - Cheap Rental Deals
</Link>

<Link to="/rent-a-luxury-car-with-driver-car-rental-companies-dubai">
Rent a Luxury Car with Driver - Car Rental Companies Dubai
</Link>

<Link to="/legend-rent-a-car-car-rental-agency-dubai-hire-auto-vehicle">
Legend Rent a Car: Car Rental Agency Dubai Hire Auto Vehicle
</Link>

<Link to="/car-rental-in-dubai-united-arab-emirates">
Car Rental in Dubai United Arab Emirates
</Link>

<Link to="/car-rental-dubai-united-arab-emirates">
Car Rental Dubai - United Arab Emirates
</Link>

<Link to="/car-leasing-in-dubai-best-car-rental">
Car Leasing in Dubai | Best Car Rental
</Link>

<Link to="/car-for-rent-dubai-low-cost-rent-car-dubai">
Car For Rent Dubai | Low Cost Rent Car Dubai
</Link>

<Link to="/dubai-luxury-car-rental-no-deposit-required">
Dubai Luxury Car Rental - No Deposit Required
</Link>

<Link to="/premium-car-rental-dubai-rent-a-car-self-drive-dubai">
Premium Car Rental Dubai - Rent a Car Self Drive Dubai
</Link>

<Link to="/rent-a-car-car-rental-agency-hire-car">
Rent a Car | Car Rental Agency | Hire car
</Link>

<Link to="/monthly-car-rental-dubai-from-aed-1390">
Monthly Car Rental Dubai from AED 1390
</Link>

<Link to="/affordable-car-rental-in-dubai-low-cost-car-rental-services">
Affordable Car Rental in Dubai | Low Cost Car Rental Services
</Link>

<Link to="/affordable-car-rental-in-dubai-rent-a-car-uae">
Affordable car rental in Dubai - Rent a Car UAE
</Link>

<Link to="/your-cheap-car-hire-in-dubai">
Your Cheap Car Hire in Dubai
</Link>

<Link to="/number-1-car-rental-in-uae-dubai-lease-our-cars-now">
#1 Car Rental in UAE, Dubai | Lease Our Cars Now
</Link>

<Link to="/luxury-car-rental-in-dubai-wow-rent-a-car">
Luxury Car Rental in Dubai - Wow Rent a Car
</Link> */









